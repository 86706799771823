import React, {Component} from "react"
import PropTypes from 'prop-types'
import {
    Col,
    Row,
    Card,
    CardBody,
    Input,
    Modal,
    Button,
} from "reactstrap"
import Select from "react-select"
import {Link} from "react-router-dom";

class MiniWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            subscribemodal: false,
            subscribemodalnr: 0,
            title: '',
            lvl1: '',
            lvl2: '',
            lvl3: '',
            queryWhere: [],
            selectedQueryWhere: [],
        }
    }

    togglesubscribemodal = (nr) => {
        let report = this.props.reports[nr];

        var title = report.title || "";
        var lvl1 = ((report || {}).query || "");
        var lvl2 = ((report || {}).queryTable || "");
        var lvl3 = ((report || {}).queryField || "");
        var queryFieldFunction = ((report || {}).queryFieldFunction || "");
        var queryWhere = ((report || {}).queryWhere || []);

        console.log(this.props);
        console.log(report);

        this.setState(prevState => ({
            subscribemodal: !prevState.subscribemodal,
            subscribemodalnr: nr,
            title: title,
            lvl1: lvl1,
            lvl2: lvl2,
            lvl3: lvl3,
            queryFieldFunction: queryFieldFunction,
            queryWhere: queryWhere,
        }));

        {
            let all = Object.keys(this.props.queries);
            for (let now in all) {
                if (lvl1 === all[now]) {
                    this.setState({selectedLvl1: {label: all[now], value: all[now]}});
                    break;
                }
            }
        }

        {
            let all = Object.keys(this.props.queries[lvl1] || {});
            for (let now in all) {
                if (lvl2 === all[now]) {
                    this.setState({selectedLvl2: {label: all[now], value: all[now]}});
                    break;
                }
            }
        }

        {
            let all = Object.keys((this.props.queries[lvl1] || {})[lvl2] || {});
            for (let now in all) {
                if (lvl3 === all[now]) {
                    this.setState({selectedLvl3: {label: all[now], value: all[now]}});
                    break;
                }
            }
        }

        {
            let all = ((this.props.queries[lvl1] || {})[lvl2] || {})[lvl3] || {};
            for (let now in all) {
                if (queryFieldFunction === all[now]) {
                    this.setState({selectedQueryFieldFunction: {label: (all[now] === '' ? '---' : all[now]), value: all[now]}});
                    break;
                }
            }
        }

        {
            // {
            //     let all = Object.keys((this.props.queries[lvl1] || {})[lvl2] || {});
            //     for (let now in all) {
            //         if (lvl3 === all[now]) {
            //             this.setState({selectedLvl3: {label: all[now], value: all[now]}});
            //             break;
            //         }
            //     }
            // }
            //
            // {
            //     let all = ((this.props.queries[lvl1] || {})[lvl2] || {})[lvl3] || {};
            //     for (let now in all) {
            //         if (queryFieldFunction === all[now]) {
            //             this.setState({selectedQueryFieldFunction: {label: (all[now] === '' ? '---' : all[now]), value: all[now]}});
            //             break;
            //         }
            //     }
            // }
        }
    }

    save() {
        let report = this.props.reports[this.state.subscribemodalnr];
        report.title = this.state.title;
        report.query = this.state.lvl1;
        report.queryTable = this.state.lvl2;
        report.queryField = this.state.lvl3;
        report.queryFieldFunction = this.state.queryFieldFunction;
        report.queryWhere = this.state.queryWhere;
        this.props.save(this.state.subscribemodalnr, report);
        this.close();
    }

    close() {
        this.setState({subscribemodal: false});
    }

    getValuesLvl1() {
        let out = [{
            options: []
        }];

        let all = Object.keys(this.props.queries);
        for (let now in all) {
            let cur = {label: all[now], value: all[now]};
            out[0].options.push(cur);
        }

        return out;
    }

    getValuesLvl2() {
        let out = [{
            options: []
        }];

        let all = Object.keys(this.props.queries[this.state.lvl1] || {});
        for (let now in all) {
            let cur = {label: all[now], value: all[now]};
            out[0].options.push(cur);
        }

        return out;
    }

    getValuesLvl3() {
        let out = [{
            options: []
        }];

        let all = Object.keys((this.props.queries[this.state.lvl1] || {})[this.state.lvl2] || {});
        for (let now in all) {
            let cur = {label: all[now], value: all[now]};
            out[0].options.push(cur);
        }

        return out;
    }

    getValuesLvl3Where(key) {
        let out = [{
            options: []
        }];

        let all = Object.keys((this.props.queries[this.state.lvl1] || {})[this.state.queryWhere[key]['query']] || {});
        for (let now in all) {
            let cur = {label: all[now], value: all[now]};
            out[0].options.push(cur);
        }

        return out;
    }

    getQueryFieldFunction() {
        let out = [{
            options: []
        }];

        let all = ((this.props.queries[this.state.lvl1] || {})[this.state.lvl2] || {})[this.state.lvl3] || {};
        for (let now in all) {
            let cur = {label: (all[now] === '' ? '---' : all[now]), value: all[now]};
            out[0].options.push(cur);
        }

        return out;
    }

    getQueryWhereOperators() {
        return [{
            options: [
                {label: 'takie same', value: '=='},
                {label: 'różne', value: '=='},
                {label: 'większe niż', value: '>'},
                {label: 'większe lub równe', value: '>='},
                {label: 'mniejsze niż', value: '<'},
                {label: 'mniejsze lub równe', value: '<='},
            ]
        }];
    }

    render() {
        const {selectedLvl1} = this.state
        const {selectedLvl2} = this.state
        const {selectedLvl3} = this.state
        const {selectedQueryFieldFunction} = this.state
        const {selectedQueryWhere} = this.state


        return (
            <React.Fragment>

                <Modal
                    isOpen={this.state.subscribemodal}
                    role="dialog"
                    autoFocus={true}
                    data-toggle="modal"
                    centered
                    toggle={this.togglesubscribemodal}
                >
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <button type="button" className="btn-close" onClick={() =>
                                this.setState({subscribemodal: false})
                            } data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className="text-center mb-4">

                                {/*<div className="avatar-md mx-auto mb-4">*/}
                                {/*    <div className="avatar-title bg-light  rounded-circle text-primary h1">*/}
                                {/*        <i className="mdi mdi-newspaper"/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="row justify-content-center">
                                    <div className="col-xl-10">
                                        <h4 className="text-primary mb-5">Edycja</h4>
                                        {/*<p className="text-muted font-size-14 mb-4">Subscribe our newletter and get notification to stay update.</p>*/}


                                        {/*{"reports":[{"icon":"bx bx-copy-alt","title":"Orders","value":"DOZ","badgeValue":"0.8673598612253274","color":"success","desc":"From previous period","query":"QueryHelper","queryTable":"cmsSiteVersion","queryField":"name","queryFieldFunction":"","queryWhere":[]}]}*/}


                                        <div className="mb-3 row">
                                            <label className="col-md-2 col-form-label">Tytuł</label>
                                            <div className="col-md-10">
                                                <Input onChange={(selected) => {
                                                    this.setState({title: selected.target.value});
                                                }} value={this.state.title} type="text" className="form-control"/>
                                            </div>
                                        </div>


                                        <div className="mb-3 row">
                                            <label className="col-md-2 col-form-label">Typ zapytania</label>
                                            <div className="col-md-10">
                                                <Select classNamePrefix="select2-selection" onChange={(selected) => {
                                                    this.setState({selectedLvl1: selected});
                                                    this.setState({selectedLvl2: null});
                                                    this.setState({selectedLvl3: null});
                                                    this.setState({selectedQueryFieldFunction: null});
                                                    this.setState({lvl1: selected.value, lvl2: '', lvl3: ''});
                                                }} value={selectedLvl1} options={this.getValuesLvl1()}/>
                                            </div>
                                        </div>


                                        <div className="mb-3 row">
                                            <label className="col-md-2 col-form-label">Tabela</label>
                                            <div className="col-md-10">
                                                <Select classNamePrefix="select2-selection" onChange={(selected) => {
                                                    this.setState({selectedLvl2: selected});
                                                    this.setState({selectedLvl3: null});
                                                    this.setState({selectedQueryFieldFunction: null});
                                                    this.setState({lvl2: selected.value, lvl3: ''});
                                                }} value={selectedLvl2} options={this.getValuesLvl2()}/>
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Pole</label>
                                            <div className="col-md-10">
                                                <Select classNamePrefix="select2-selection" onChange={(selected) => {
                                                    this.setState({selectedLvl3: selected})
                                                    this.setState({selectedQueryFieldFunction: null});
                                                    this.setState({lvl3: selected.value});
                                                }} value={selectedLvl3} options={this.getValuesLvl3()}/>
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Funkcja</label>
                                            <div className="col-md-10">
                                                <Select classNamePrefix="select2-selection" onChange={(selected) => {
                                                    this.setState({selectedQueryFieldFunction: selected})
                                                    this.setState({queryFieldFunction: selected.value});
                                                }} value={selectedQueryFieldFunction} options={this.getQueryFieldFunction()}/>
                                            </div>
                                        </div>

                                        {/*<div className="mb-3 row">*/}
                                        {/*    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Warunek</label>*/}
                                        {/*    <div className="col-md-10">*/}
                                        {/*        {this.state.queryWhere.map((v, key, arr) => (*/}
                                        {/*            <Row key={key}>*/}
                                        {/*                <Col lg="12">*/}
                                        {/*                    <Select classNamePrefix="select2-selection" onChange={(selected) => {*/}
                                        {/*                        arr[key].l1 = selected.value;*/}
                                        {/*                        arr[key].l2 = '';*/}
                                        {/*                        this.setState({queryWhere: arr});*/}
                                        {/*                        selectedQueryWhere[key]['l2'] = '';*/}
                                        {/*                        selectedQueryWhere[key]['l1'] = selected;*/}
                                        {/*                    }} value={selectedQueryWhere[key]['l1']} options={this.getValuesLvl2()}/>*/}
                                        {/*                </Col>*/}
                                        
                                        {/*                <Col lg="12">*/}
                                        {/*                <Select classNamePrefix="select2-selection" onChange={(selected) => {*/}
                                        {/*                        arr[key].l2 = selected.value;*/}
                                        {/*                        this.setState({queryWhere: arr});*/}
                                        {/*                        selectedQueryWhere[key]['l2'] = selected;*/}
                                        {/*                    }} value={selectedQueryWhere[key]['l2']} options={this.getValuesLvl3Where(key)}/>*/}
                                        {/*                </Col>*/}
                                        
                                        {/*                <Col lg="12">*/}
                                        {/*                    <Select classNamePrefix="select2-selection" onChange={(selected) => {*/}
                                        {/*                        arr[key].o = selected.value;*/}
                                        {/*                        this.setState({queryWhere: arr});*/}
                                        {/*                        selectedQueryWhere[key]['o'] = selected;*/}
                                        {/*                    }} value={selectedQueryWhere[key]['o']} options={this.getQueryWhereOperators()}/>*/}
                                        {/*                </Col>*/}
                                        
                                        {/*                <Col lg="12">*/}
                                        {/*                    <Input onChange={(selected) => {*/}
                                        {/*                        arr[key].r = selected.value;*/}
                                        {/*                        this.setState({queryWhere: arr});*/}
                                        {/*                        selectedQueryWhere[key]['r'] = selected.value;*/}
                                        {/*                    }} value={selectedQueryWhere[key]['r']} type="text" className="form-control"/>*/}
                                        {/*                </Col>*/}
                                        {/*            </Row>*/}
                                        {/*        ))}*/}
                                        {/*    </div>*/}
                                        
                                        
                                        {/*    <Link to="#" onClick={() => {*/}
                                        {/*        this.state.queryWhere.push({'l': '', 'o': '', 'r': ''});*/}
                                        {/*        this.state.selectedQueryWhere.push({'l': '', 'o': '', 'r': ''});*/}
                                        {/*    }}>add</Link>*/}
                                        {/*</div>*/}


                                        <Row className="mt-4">
                                            <div>
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="w-md me-4"
                                                    onClick={(e) => {
                                                        this.save()
                                                    }}
                                                >
                                                    Zapisz
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    color="secondary"
                                                    className="w-md"
                                                    onClick={(e) => {
                                                        this.close()
                                                    }}
                                                >
                                                    Zamknij
                                                </Button>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>


                {this.props.reports.map((report, key) => (
                    <Col sm="4" key={key}>
                        <Card>
                            <CardBody>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="avatar-xs me-3">
                                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                            <i className={report.icon}/>
                                        </span>
                                    </div>
                                    <h5 className="font-size-14 mb-0">{report.title}</h5>

                                    <div className="flex-fill text-end">
                                        <i role="button" className="mdi mdi-lead-pencil me-2" onClick={() => {
                                            this.togglesubscribemodal(key);
                                        }}/>
                                        <i role="button" className="mdi mdi-close-thick" onClick={() => {
                                            this.props.remove(key)
                                        }}/>
                                    </div>
                                </div>
                                <div className="text-muted mt-4">
                                    <h4>
                                        {report.value}{" "}
                                        {/*<i className="mdi mdi-chevron-up ms-1 text-success"/>*/}
                                    </h4>
                                    {/*<div className="d-flex">*/}
                                    {/*    <span className={"badge badge-soft-" + report.color + " font-size-12"}>*/}
                                    {/*      {" "}*/}
                                    {/*        {report.badgeValue}{" "}*/}
                                    {/*    </span>{" "}*/}
                                    {/*    <span className="ms-2 text-truncate">{report.desc}</span>*/}
                                    {/*</div>*/}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </React.Fragment>
        )
    }
}

MiniWidget.propTypes = {
    reports: PropTypes.array,
    queries: PropTypes.object,
    remove: PropTypes.func,
    save: PropTypes.func,
}

export default MiniWidget
