import pl from "../assets/images/flags/pl.png";
import us from "../assets/images/flags/us.png";

const languages = {
  pl: {
    label: "Polski",
    flag: pl,
  },
  en: {
    label: "English",
    flag: us,
  },
}

export default languages;
