import React, {Component} from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col
} from "reactstrap"

import MiniWidget from "./mini-widget"
import {Link} from "react-router-dom";

import * as url from "../../helpers/url_helper"

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reports: [],
            queries: {none: {}}
        };

        this.loadDashboard();
        this.loadQueries();
    }


    loadDashboard() {
        fetch(url.GET_DASBOARD_INIT, {method: 'GET'})
            .then(response => response.json())
            .then(data => {
                for (let key in data) {
                    this.setState({[key]: data[key]})
                }
            });
    }

    loadQueries() {
        fetch(url.GET_QUERIES, {method: 'GET'})
            .then(response => response.json())
            .then(data => {
                this.setState({queries: data})
            });
    }

    saveDashboard() {
        fetch(url.GET_DASBOARD_INIT, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({reports: this.state.reports})
        })
            .then(response => response.json())
            .then(data => {
                for (let key in data) {
                    this.setState({[key]: data[key]})
                }
            });
    }

    removeReport(key) {
        this.setState({
            reports: this.state.reports.filter(function (_, currentKey) {
                return currentKey !== key;
            })
        });
    }

    updateReport(key, value) {
        let a = this.state.reports;
        a[key] = value;
        this.setState({
            reports: a
        });

        this.saveDashboard();
    }

    addDashboard() {
        let a = this.state.reports;

        let max = 0;
        for (let cur in Object.keys(a)) {
            if (cur > max) {
                max = cur;
            }
        }

        a[max + 1] = {};
        this.setState({
            reports: a
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Dashboard | Skote - React Admin & Dashboard Template</title>
                    </MetaTags>
                    <Container fluid>
                        <h4>Dashboard</h4>

                        <Link to="#" onClick={() => {
                            this.loadDashboard()
                        }}>Load</Link>

                        |

                        <Link to="#" onClick={() => {
                            this.saveDashboard()
                        }}>save</Link>

                        |

                        <Link to="#" onClick={() => {
                            this.addDashboard()
                        }}>add</Link>

                        <Row>
                            {/*mimi widgets */}
                            <MiniWidget reports={this.state.reports} queries={this.state.queries} remove={(key) => {
                                this.removeReport(key);
                            }} save={(key, value) => {
                                this.updateReport(key, value);
                            }}/>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Dashboard;
