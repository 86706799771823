import PropTypes from "prop-types";
import React, {Component} from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";

//i18n
import {withTranslation} from "react-i18next";

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.refDiv = React.createRef();
    }

    componentDidMount() {
        this.initMenu();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, ss) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    // componentDidUpdate() {}

    scrollElement = item => {
        setTimeout(() => {
            if (this.refDiv.current !== null) {
                if (item) {
                    const currentPosition = item.offsetTop;
                    if (currentPosition > window.innerHeight) {
                        if (this.refDiv.current)
                            this.refDiv.current.getScrollElement().scrollTop =
                                currentPosition - 300;
                    }
                }
            }
        }, 300);
    };

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            this.scrollElement(item);
            return false;
        }
        this.scrollElement(item);
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <SimpleBar className="h-100" ref={this.refDiv}>
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title">{this.props.t("Menu")}</li>

                            <li>
                                <Link to="/#">
                                    <i className="bx bx-home-circle"/>
                                    <span>{this.props.t("Dashboard")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/panel">{this.props.t("Summary")}</Link>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-chip"/>
                                    <span>{this.props.t("Manage")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Phrase")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/frazy/nowe">{this.props.t("New")}</Link></li>
                                            <li><Link to="/panel/brz/frazy/lista">{this.props.t("List")}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Products - Vendors")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/producent/nowe">{this.props.t("New")}</Link></li>
                                            <li><Link to="/panel/brz/producent/lista">{this.props.t("List")}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Products - Brands")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/marka/nowe">{this.props.t("New")}</Link></li>
                                            <li><Link to="/panel/brz/marka/lista">{this.props.t("List")}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Products - Products")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/produkt/nowe">{this.props.t("New")}</Link></li>
                                            <li><Link to="/panel/brz/produkt/lista">{this.props.t("List")}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Products - Variants")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/wersja/nowe">{this.props.t("New")}</Link></li>
                                            <li><Link to="/panel/brz/wersja/lista">{this.props.t("List")}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Pages - Shops")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/apteki/nowe">{this.props.t("New")}</Link></li>
                                            <li><Link to="/panel/brz/apteki/lista">{this.props.t("List")}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Pages - Sites")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/witryna/nowe">{this.props.t("New")}</Link></li>
                                            <li><Link to="/panel/brz/witryna/lista">{this.props.t("List")}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Pages - Sites - Product variant")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/witryna-wariant/nowe">{this.props.t("New")}</Link></li>
                                            <li><Link to="/panel/brz/witryna-wariant/lista">{this.props.t("List")}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Pages - Sites - User groups")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/witryna-grupa/nowe">{this.props.t("New")}</Link></li>
                                            <li><Link to="/panel/brz/witryna-grupa/lista">{this.props.t("List")}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Pages - Sites - Product variant - User group")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/witryna-wariant-grupa/nowe">{this.props.t("New")}</Link></li>
                                            <li><Link to="/panel/brz/witryna-wariant-grupa/lista">{this.props.t("List")}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Exposition")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/pozycje-w-kategorii/nowe">{this.props.t("New")}</Link></li>
                                            <li><Link to="/panel/brz/pozycje-w-kategorii/lista">{this.props.t("List")}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Tags")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/tagi/nowe">{this.props.t("New")}</Link></li>
                                            <li><Link to="/panel/brz/tagi/lista">{this.props.t("List")}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/panel" className="has-arrow">{this.props.t("Reports")}</Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/brz/produkty-do-pobrania-wszystkie/nowe">{this.props.t("Products to download")}</Link></li>
                                            <li><Link to="/panel/brz/produkty-pobrane-wielokrotnie/lista">{this.props.t("Multiple downloaded")}</Link></li>
                                            <li><Link to="/panel/brz/witryna-wariant-dane/lista">{this.props.t("Downloaded products")}</Link></li>
                                            <li><Link to="/panel/brz/monitorowane-produkty/lista-under">{this.props.t("Under in cache")}</Link></li>
                                            <li><Link to="/panel/brz/monitorowane-produkty/lista-over">{this.props.t("Over in cache")}</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-shopping-bag"/>
                                    <span>{this.props.t("Products")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li><Link to="/panel/monitorowane-produkty/lista">{this.props.t("Products list")}</Link></li>
                                    <li><Link to="/panel/monitorowane-produkty/lista/niedostepne">{this.props.t("Not available list")}</Link></li>
                                    <li><Link to="/panel/monitorowane-produkty/lista-szczegolowa">{this.props.t("Detailed list")}</Link></li>
                                    <li><Link to="/panel/monitorowane-produkty/produkty-do-pobrania/lista">{this.props.t("Products to download")}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-check-circle"/>
                                    <span>{this.props.t("Availability")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li>
                                        <Link to="/#" className="has-arrow">
                                            <span>{this.props.t("Current")}</span>
                                        </Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/podsumowanie-2/sklepy">{this.props.t("By shops")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-2/marki">{this.props.t("By brands")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-2/produkty">{this.props.t("By products")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-2/warianty">{this.props.t("By variants")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-2/apteki-producenci">{this.props.t("By shops-vendors")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-2/apteki-marki">{this.props.t("By shops-brands")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-2/apteki-produkty">{this.props.t("By shops-products")}</Link></li>
                                        </ul>
                                    </li>

                                    <li>
                                        <Link to="/#" className="has-arrow">
                                            <span>{this.props.t("Archive")}</span>
                                        </Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/podsumowanie-szczegolowe/sklepy">{this.props.t("By shops")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-szczegolowe/marki">{this.props.t("By brands")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-szczegolowe/produkty">{this.props.t("By products")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-szczegolowe/warianty">{this.props.t("By variants")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-szczegolowe/apteki-producenci">{this.props.t("By shops-vendors")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-szczegolowe/apteki-marki">{this.props.t("By shops-brands")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-szczegolowe/apteki-produkty">{this.props.t("By shops-products")}</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-shopping-bag"/>
                                    <span>{this.props.t("Products MSL")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li><Link to="/panel/monitorowane-produkty-msl/lista">{this.props.t("Products list")}</Link></li>
                                    <li><Link to="/panel/monitorowane-produkty-btn header-item noti-icon right-bar-togglemsl/lista/niedostepne">{this.props.t("Not available list")}</Link></li>
                                    <li><Link to="/panel/monitorowane-produkty-msl/lista-szczegolowa">{this.props.t("Detailed list")}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-check-circle"/>
                                    <span>{this.props.t("Availability MSL")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li>
                                        <Link to="/#" className="has-arrow">
                                            <span>{this.props.t("Current")}</span>
                                        </Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/podsumowanie-2-msl/sklepy">{this.props.t("By shops")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-2-msl/marki">{this.props.t("By brands")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-2-msl/produkty">{this.props.t("By products")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-2-msl/warianty">{this.props.t("By variants")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-2-msl/apteki-producenci">{this.props.t("By shops-vendors")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-2-msl/apteki-marki">{this.props.t("By shops-brands")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-2-msl/apteki-produkty">{this.props.t("By shops-products")}</Link></li>
                                        </ul>
                                    </li>

                                    <li>
                                        <Link to="/#" className="has-arrow">
                                            <span>{this.props.t("Archive")}</span>
                                        </Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/podsumowanie-szczegolowe-msl/sklepy">{this.props.t("By shops")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-szczegolowe-msl/marki">{this.props.t("By brands")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-szczegolowe-msl/produkty">{this.props.t("By products")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-szczegolowe-msl/warianty">{this.props.t("By variants")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-szczegolowe-msl/apteki-producenci">{this.props.t("By shops-vendors")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-szczegolowe-msl/apteki-marki">{this.props.t("By shops-brands")}</Link></li>
                                            <li><Link to="/panel/podsumowanie-szczegolowe-msl/apteki-produkty">{this.props.t("By shops-products")}</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-globe-alt"/>
                                    <span>{this.props.t("Exposition")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li><Link to="/panel/pozycje-w-kategorii/raport-zmian-pozycji">{this.props.t("Position changes")}</Link></li>
                                    <li><Link to="/panel/pozycje-w-kategorii/raport-zmian-ekspozycji">{this.props.t("Exposition changes")}</Link></li>
                                    <li><Link to="/panel/pozycje-w-kategorii/raport-obecny">{this.props.t("Exposition")}</Link></li>
                                    <li><Link to="/panel/pozycje-w-kategorii/raport-archiwum">{this.props.t("Exposition archive")}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-world"/>
                                    <span>{this.props.t("Exposition standard")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li>
                                        <Link to="/#" className="has-arrow">
                                            <span>{this.props.t("Current")}</span>
                                        </Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja/sklepy">{this.props.t("By shops")}</Link></li>
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja/marki">{this.props.t("By brands")}</Link></li>
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja/produkty">{this.props.t("By products")}</Link></li>
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja/warianty">{this.props.t("By variants")}</Link></li>
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja/apteki-producenci">{this.props.t("By shops-vendors")}</Link></li>
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja/apteki-marki">{this.props.t("By shops-brands")}</Link></li>
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja/apteki-produkty">{this.props.t("By shops-products")}</Link></li>
                                        </ul>
                                    </li>

                                    <li>
                                        <Link to="/#" className="has-arrow">
                                            <span>{this.props.t("Archive")}</span>
                                        </Link>
                                        <ul className="sub-menu" aria-expanded="true">
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja-szczegolowe/sklepy">{this.props.t("By shops")}</Link></li>
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja-szczegolowe/marki">{this.props.t("By brands")}</Link></li>
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja-szczegolowe/produkty">{this.props.t("By products")}</Link></li>
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja-szczegolowe/warianty">{this.props.t("By variants")}</Link></li>
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja-szczegolowe/apteki-producenci">{this.props.t("By shops-vendors")}</Link></li>
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja-szczegolowe/apteki-marki">{this.props.t("By shops-brands")}</Link></li>
                                            <li><Link to="/panel/monitorowane-produkty-ekspozycja-szczegolowe/apteki-produkty">{this.props.t("By shops-products")}</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-dollar-circle"/>
                                    <span>{this.props.t("Prices")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li><Link to="/panel/ceny/rekomendowane-lista">{this.props.t("Summary referential prices")}</Link></li>
                                    <li><Link to="/panel/ceny/rekomendowane-szczegoly">{this.props.t("Details referential prices")}</Link></li>
                                    <li><Link to="/panel/ceny/zmian-cen">{this.props.t("Prices changes")}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-search"/>
                                    <span>{this.props.t("Search results")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li><Link to="/panel/monitorowane-frazy/lista">{this.props.t("Search results")}</Link></li>
                                    <li><Link to="/panel/monitorowane-frazy/lista-szczegolowa">{this.props.t("Archive search results")}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-fingerprint"/>
                                    <span>{this.props.t("References")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li><Link to="/panel/referencje-zdjec/lista">{this.props.t("Image references")}</Link></li>
                                    <li><Link to="/panel/referencje-opisow/lista">{this.props.t("Description references")}</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-archive"/>
                                    <span>{this.props.t("Logs")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li><Link to="/panel/logowania/lista">{this.props.t("Login logs")}</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </SimpleBar>
            </React.Fragment>
        );
    }
}

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
